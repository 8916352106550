@font-face {
  font-family: "polifont";
  src: url("../fonts/polifont.eot?clk059");
  src: url("../fonts/polifont.eot?clk059#iefix") format("embedded-opentype"),
    url("../fonts/polifont.ttf?clk059") format("truetype"),
    url("../fonts/polifont.woff?clk059") format("woff"),
    url("../fonts/polifont.svg?clk059#polifont") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


[class^="pi-"],
[class*=" pi-"] {
  /* use !importantf mext prevent issues with browser extensions that change fonts */
  font-family: "polifont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pi-external-link-line:before {
  content: "\ea63";
}
.pi-external-link-fill:before {
  content: "\ea64";
}
.pi-bol-line:before {
  content: "\ea55";
}
.pi-week-line:before {
  content: "\ea56";
}
.pi-bol-fill:before {
  content: "\ea57";
}
.pi-week-fill:before {
  content: "\ea58";
}
.pi-debit-line:before {
  content: "\ea59";
}
.pi-year-line:before {
  content: "\ea5a";
}
.pi-sales-chart-line:before {
  content: "\ea5b";
}
.pi-credit-card-line:before {
  content: "\ea5c";
}
.pi-day-line:before {
  content: "\ea5d";
}
.pi-debit-fill:before {
  content: "\ea5e";
}
.pi-year-fill:before {
  content: "\ea5f";
}
.pi-sales-chart-fill:before {
  content: "\ea60";
}
.pi-credit-card-fill:before {
  content: "\ea61";
}
.pi-day-fill:before {
  content: "\ea62";
}
.pi-remove-circle-fill:before {
  content: "\ea51";
}
.pi-link-delete-fill:before {
  content: "\ea52";
}
.pi-link-delete-line:before {
  content: "\ea53";
}
.pi-remove-circle-line:before {
  content: "\ea54";
}
.pi-video-line:before {
  content: "\e967";
}
.pi-variable-line:before {
  content: "\ea49";
}
.pi-shortcuts-line:before {
  content: "\ea4a";
}
.pi-hashtag-line:before {
  content: "\ea4b";
}
.pi-video-stop-line:before {
  content: "\ea4c";
}
.pi-variable-fill:before {
  content: "\ea4d";
}
.pi-shortcuts-fill:before {
  content: "\ea4e";
}
.pi-hashtag-fill:before {
  content: "\ea4f";
}
.pi-video-stop-fill:before {
  content: "\ea50";
}
.pi-video-pause-line:before {
  content: "\ea47";
}
.pi-video-pause-fill:before {
  content: "\ea48";
}

.pi-alert-fill:before {
  content: "\ea3f";
}
.pi-type-code-line:before {
  content: "\ea40";
}
.pi-strike-line:before {
  content: "\ea41";
}
.pi-type-code-fill:before {
  content: "\ea42";
}
.pi-strike-fill:before {
  content: "\ea43";
}
.pi-what-line:before {
  content: "\ea44";
}
.pi-alert-line:before {
  content: "\ea45";
}
.pi-what-fill:before {
  content: "\ea46";
}
.pi-share-arrow-fill:before {
  content: "\e975";
}
.pi-share-arrow-line:before {
  content: "\e976";
}
.pi-instagram-line:before {
  content: "\e9a0";
}
.pi-instagram-fill:before {
  content: "\e9a1";
}
.pi-linkedin-line:before {
  content: "\ea3b";
}
.pi-reply-arrow-line:before {
  content: "\ea3c";
}
.pi-linkedin-fill:before {
  content: "\ea3d";
}
.pi-reply-arrow-fill:before {
  content: "\ea3e";
}
.pi-camera-video-fill:before {
  content: "\e900";
}
.pi-camera-video-line:before {
  content: "\e901";
}
.pi-podcast-circle-fill:before {
  content: "\e902";
}
.pi-podcast-circle-line:before {
  content: "\e903";
}
.pi-video-play-fill:before {
  content: "\e904";
}
.pi-video-play-line:before {
  content: "\e905";
}
.pi-user-woman-line:before {
  content: "\e906";
}
.pi-channels-line:before {
  content: "\e907";
}
.pi-close-line:before {
  content: "\e908";
}
.pi-laptop-fill:before {
  content: "\e909";
}
.pi-laptop-line:before {
  content: "\e90a";
}
.pi-computer-fill:before {
  content: "\e90b";
}
.pi-admin-polichat-fill:before {
  content: "\e90c";
}
.pi-arrow-right-fill:before {
  content: "\e90d";
}
.pi-arrow-left-fill:before {
  content: "\e90e";
}
.pi-arrow-right-line:before {
  content: "\e90f";
}
.pi-arrow-left-line:before {
  content: "\e910";
}
.pi-arrow-up-fill:before {
  content: "\e911";
}
.pi-arrow-down-fill:before {
  content: "\e912";
}
.pi-clock-fill:before {
  content: "\e913";
}
.pi-arrow-up-line:before {
  content: "\e914";
}
.pi-arrow-down-line:before {
  content: "\e915";
}
.pi-clock-line:before {
  content: "\e916";
}
.pi-computer-line:before {
  content: "\e917";
}
.pi-admin-polichat-line:before {
  content: "\e918";
}
.pi-add-fill:before {
  content: "\e919";
}
.pi-close-fill:before {
  content: "\e91a";
}
.pi-bell-fill:before {
  content: "\e91b";
}
.pi-training-fill:before {
  content: "\e91c";
}
.pi-bell-line:before {
  content: "\e91d";
}
.pi-training-line:before {
  content: "\e91e";
}
.pi-protocol-line:before {
  content: "\e91f";
}
.pi-perfil-line:before {
  content: "\e920";
}
.pi-contacts-line:before {
  content: "\e921";
}
.pi-protocol-fill:before {
  content: "\e922";
}
.pi-perfil-fill:before {
  content: "\e923";
}
.pi-contacts-fill:before {
  content: "\e924";
}
.pi-user-woman-fill:before {
  content: "\e925";
}
.pi-channels-fill:before {
  content: "\e926";
}
.pi-set-line:before {
  content: "\e927";
}
.pi-set-fill:before {
  content: "\e928";
}
.pi-company-line:before {
  content: "\e929";
}
.pi-finish-line:before {
  content: "\e92a";
}
.pi-company-fill:before {
  content: "\e92b";
}
.pi-finish-fill:before {
  content: "\e92c";
}
.pi-queue-line:before {
  content: "\e92d";
}
.pi-queue-fill:before {
  content: "\e92e";
}
.pi-talks-line:before {
  content: "\e92f";
}
.pi-talks-fill:before {
  content: "\e930";
}
.pi-sad-line:before {
  content: "\e931";
}
.pi-sad-fill:before {
  content: "\e932";
}
.pi-check-one-line:before {
  content: "\e933";
}
.pi-check-one-fill:before {
  content: "\e934";
}
.pi-check-two-line:before {
  content: "\e935";
}
.pi-check-two-fill:before {
  content: "\e936";
}
.pi-smille-line:before {
  content: "\e937";
}
.pi-smille-fill:before {
  content: "\e938";
}
.pi-messenger-line:before {
  content: "\e939";
}
.pi-update-line:before {
  content: "\e93a";
}
.pi-update-fill:before {
  content: "\e93b";
}
.pi-apresentation-file-line:before {
  content: "\e93c";
}
.pi-apresentation-file-fill:before {
  content: "\e93d";
}
.pi-word-file-line:before {
  content: "\e93e";
}
.pi-word-file-fill:before {
  content: "\e93f";
}
.pi-excel-file-line:before {
  content: "\e940";
}
.pi-excel-file-fill:before {
  content: "\e941";
}
.pi-text-center-line:before {
  content: "\e942";
}
.pi-text-right-line:before {
  content: "\e943";
}
.pi-text-left-line:before {
  content: "\e944";
}
.pi-text-center-fill:before {
  content: "\e945";
}
.pi-text-right-fill:before {
  content: "\e946";
}
.pi-text-left-fill:before {
  content: "\e947";
}
.pi-italic-line:before {
  content: "\e948";
}
.pi-bold-line:before {
  content: "\e949";
}
.pi-italic-fill:before {
  content: "\e94a";
}
.pi-bold-fill:before {
  content: "\e94b";
}
.pi-design-line:before {
  content: "\e94c";
}
.pi-design-fill:before {
  content: "\e94d";
}
.pi-tag-line:before {
  content: "\e94e";
}
.pi-tag-fill:before {
  content: "\e94f";
}
.pi-tool-line:before {
  content: "\e950";
}
.pi-tool-fill:before {
  content: "\e951";
}
.pi-timer-line:before {
  content: "\e952";
}
.pi-timer-fill:before {
  content: "\e953";
}
.pi-cut-line:before {
  content: "\e954";
}
.pi-cut-fill:before {
  content: "\e955";
}
.pi-copy-line:before {
  content: "\e956";
}
.pi-copy-fill:before {
  content: "\e957";
}
.pi-code-line:before {
  content: "\e958";
}
.pi-code-fill:before {
  content: "\e959";
}
.pi-flag-line:before {
  content: "\e95a";
}
.pi-flag-fill:before {
  content: "\e95b";
}
.pi-pencil-line:before {
  content: "\e95c";
}
.pi-pencil-fill:before {
  content: "\e95d";
}
.pi-folder-line:before {
  content: "\e95e";
}
.pi-folder-fill:before {
  content: "\e95f";
}
.pi-file-line:before {
  content: "\e960";
}
.pi-pdf-file-line:before {
  content: "\e961";
}
.pi-file-fill:before {
  content: "\e962";
}
.pi-pdf-file-fill:before {
  content: "\e963";
}
.pi-song-file-fill:before {
  content: "\e964";
}
.pi-zip-line:before {
  content: "\e965";
}
.pi-zip-fill:before {
  content: "\e966";
}
.pi-video-line:before {
  content: "\e967";
}
.pi-video-fill:before {
  content: "\e968";
}
.pi-photo-line:before {
  content: "\e969";
}
.pi-photo-fill:before {
  content: "\e96a";
}
.pi-attach-line:before {
  content: "\e96b";
}
.pi-attach-fill:before {
  content: "\e96c";
}
.pi-delete-line:before {
  content: "\e96d";
}
.pi-delete-fill:before {
  content: "\e96e";
}
.pi-cloud-line:before {
  content: "\e96f";
}
.pi-cloud-fill:before {
  content: "\e970";
}
.pi-upload-line:before {
  content: "\e971";
}
.pi-upload-fill:before {
  content: "\e972";
}
.pi-download-line:before {
  content: "\e973";
}
.pi-download-fill:before {
  content: "\e974";
}
.pi-bullet-line:before {
  content: "\e977";
}
.pi-mail-line:before {
  content: "\e978";
}
.pi-bullet-fill:before {
  content: "\e979";
}
.pi-mail-fill:before {
  content: "\e97a";
}
.pi-webchat-line:before {
  content: "\e97b";
}
.pi-webchat-fill:before {
  content: "\e97c";
}
.pi-messenger-fill:before {
  content: "\e97d";
}
.pi-whatsapp-fill:before {
  content: "\e97e";
}
.pi-whatsapp-line:before {
  content: "\e97f";
}
.pi-user-man-fill:before {
  content: "\e980";
}
.pi-user-man-line:before {
  content: "\e981";
}
.pi-filter-fill:before {
  content: "\e982";
}
.pi-filter-line:before {
  content: "\e983";
}
.pi-zoom-line:before {
  content: "\e984";
}
.pi-zoom-fill:before {
  content: "\e985";
}
.pi-walk-line:before {
  content: "\e986";
}
.pi-walk-fill:before {
  content: "\e987";
}
.pi-twitter-line:before {
  content: "\e988";
}
.pi-twitter-fill:before {
  content: "\e989";
}
.pi-link-line:before {
  content: "\e98a";
}
.pi-link-fill:before {
  content: "\e98b";
}
.pi-setting-fill:before {
  content: "\e98c";
}
.pi-setting-line:before {
  content: "\e98d";
}
.pi-search-fill:before {
  content: "\e98e";
}
.pi-search-line:before {
  content: "\e98f";
}
.pi-position-line:before {
  content: "\e990";
}
.pi-position-fill:before {
  content: "\e991";
}
.pi-out-fill:before {
  content: "\e992";
}
.pi-out-line:before {
  content: "\e993";
}
.pi-send-line:before {
  content: "\e994";
}
.pi-send-fill:before {
  content: "\e995";
}
.pi-navigation-line:before {
  content: "\e996";
}
.pi-navigation-fill:before {
  content: "\e997";
}
.pi-message-fill:before {
  content: "\e998";
}
.pi-message-line:before {
  content: "\e999";
}
.pi-map-line:before {
  content: "\e99a";
}
.pi-map-fill:before {
  content: "\e99b";
}
.pi-location-line:before {
  content: "\e99c";
}
.pi-location-fill:before {
  content: "\e99d";
}
.pi-loading-line:before {
  content: "\e99e";
}
.pi-loading-fill:before {
  content: "\e99f";
}
.pi-question-fill:before {
  content: "\e9a2";
}
.pi-question-line:before {
  content: "\e9a3";
}
.pi-info-line:before {
  content: "\e9a4";
}
.pi-info-fill:before {
  content: "\e9a5";
}
.pi-google-line:before {
  content: "\e9a6";
}
.pi-youtube-line:before {
  content: "\e9a7";
}
.pi-youtube-fill:before {
  content: "\e9a8";
}
.pi-google-fill:before {
  content: "\e9a9";
}
.pi-preferences-fill:before {
  content: "\e9aa";
}
.pi-shop-fill:before {
  content: "\e9ab";
}
.pi-home-fill:before {
  content: "\e9ac";
}
.pi-schedule-fill:before {
  content: "\e9ad";
}
.pi-operator-fill:before {
  content: "\e9ae";
}
.pi-chart-fill:before {
  content: "\e9af";
}
.pi-tools-fill:before {
  content: "\e9b0";
}
.pi-apps-fill:before {
  content: "\e9b1";
}
.pi-point-fill:before {
  content: "\e9b2";
}
.pi-point-line:before {
  content: "\e9b3";
}
.pi-apps-line:before {
  content: "\e9b4";
}
.pi-tools-line:before {
  content: "\e9b5";
}
.pi-chart-line:before {
  content: "\e9b6";
}
.pi-operator-line:before {
  content: "\e9b7";
}
.pi-schedule-line:before {
  content: "\e9b8";
}
.pi-home-line:before {
  content: "\e9b9";
}
.pi-preferences-line:before {
  content: "\e9ba";
}
.pi-facebook-line:before {
  content: "\e9bb";
}
.pi-facebook-fill:before {
  content: "\e9bc";
}
.pi-eye-hide-fill:before {
  content: "\e9bd";
}
.pi-eye-hide-line:before {
  content: "\e9be";
}
.pi-eye-line:before {
  content: "\e9bf";
}
.pi-eye-fill:before {
  content: "\e9c0";
}
.pi-coffee-line:before {
  content: "\e9c1";
}
.pi-coffee-Fill:before {
  content: "\e9c2";
}
.pi-close-circle-line:before {
  content: "\e9c3";
}
.pi-car-line:before {
  content: "\e9c4";
}
.pi-car-fill:before {
  content: "\e9c5";
}
.pi-calendar-fill:before {
  content: "\e9c6";
}
.pi-calendar-line:before {
  content: "\e9c7";
}
.pi-bookmarked-fill:before {
  content: "\e9c8";
}
.pi-bookmark-line:before {
  content: "\e9c9";
}
.pi-server-line:before {
  content: "\e9ca";
}
.pi-server-fill:before {
  content: "\e9cb";
}
.pi-request-line:before {
  content: "\e9cc";
}
.pi-request-fill:before {
  content: "\e9cd";
}
.pi-whatsapp-api-line:before {
  content: "\e9ce";
}
.pi-whatsapp-api-fill:before {
  content: "\e9cf";
}
.pi-alerts-line:before {
  content: "\e9d0";
}
.pi-alerts-fill:before {
  content: "\e9d1";
}
.pi-permission-line:before {
  content: "\e9d2";
}
.pi-permission-fill:before {
  content: "\e9d3";
}
.pi-positions-line:before {
  content: "\e9d4";
}
.pi-cluster-line:before {
  content: "\e9d5";
}
.pi-correct-line:before {
  content: "\e9d6";
}
.pi-channels-conections-line:before {
  content: "\e9d7";
}
.pi-dashboard-customers-line:before {
  content: "\e9d8";
}
.pi-positions-fill:before {
  content: "\e9d9";
}
.pi-cluster-fill:before {
  content: "\e9da";
}
.pi-correct-fill:before {
  content: "\e9db";
}
.pi-channels-conections-fill:before {
  content: "\e9dc";
}
.pi-dashboard-customers-fill:before {
  content: "\e9dd";
}
.pi-list-organizations-line:before {
  content: "\e9de";
}
.pi-list-customers-line:before {
  content: "\e9df";
}
.pi-list-organizations-fill:before {
  content: "\e9e0";
}
.pi-list-customers-fill:before {
  content: "\e9e1";
}
.pi-smartphone-line:before {
  content: "\e9e2";
}
.pi-smartphone-fill:before {
  content: "\e9e3";
}
.pi-firewall-line:before {
  content: "\e9e4";
}
.pi-firewall-fill:before {
  content: "\e9e5";
}
.pi-safe-box-line:before {
  content: "\e9e6";
}
.pi-safe-box-fill:before {
  content: "\e9e7";
}
.pi-key-line:before {
  content: "\e9e8";
}
.pi-key-fill:before {
  content: "\e9e9";
}
.pi-password-line:before {
  content: "\e9ea";
}
.pi-simple-arrow-up-line:before {
  content: "\e9eb";
}
.pi-simple-arrow-down-line:before {
  content: "\e9ec";
}
.pi-password-fill:before {
  content: "\e9ed";
}
.pi-simple-arrow-up-fill:before {
  content: "\e9ee";
}
.pi-simple-arrow-down-fill:before {
  content: "\e9ef";
}
.pi-simple-arrow-right-line:before {
  content: "\e9f0";
}
.pi-simple-arrow-right-line-animation:before {
  content: "\e9f0";
}
.pi-simple-arrow-left-line:before {
  content: "\e9f1";
}
.pi-simple-arrow-right-fill:before {
  content: "\e9f2";
}
.pi-simple-arrow-left-fill:before {
  content: "\e9f3";
}
.pi-qr-code-line:before {
  content: "\e9f4";
}
.pi-conection-line:before {
  content: "\e9f5";
}
.pi-add-line:before {
  content: "\e9f6";
}
.pi-add-circle-line:before {
  content: "\e9f7";
}
.pi-close-circle-fill:before {
  content: "\e9f8";
}
.pi-add-circle-fill:before {
  content: "\e9f9";
}
.pi-qr-code-fill:before {
  content: "\e9fa";
}
.pi-conection-fill:before {
  content: "\e9fb";
}
.pi-polichat-logo-fill:before {
  content: "\e9fc";
}
.pi-polichat-logo-line:before {
  content: "\e9fd";
}
.pi-sun-line:before {
  content: "\e9fe";
}
.pi-moon-line:before {
  content: "\e9ff";
}
.pi-earth-line:before {
  content: "\ea00";
}
.pi-sun-fill:before {
  content: "\ea01";
}
.pi-moon-fill:before {
  content: "\ea02";
}
.pi-earth-fill:before {
  content: "\ea03";
}
.pi-dont-read-line:before {
  content: "\ea04";
}
.pi-read-line:before {
  content: "\ea05";
}
.pi-dont-read-fill:before {
  content: "\ea06";
}
.pi-read-fill:before {
  content: "\ea07";
}
.pi-diamond-fill:before {
  content: "\ea08";
}
.pi-diamond-line:before {
  content: "\ea09";
}
.pi-award-fill:before {
  content: "\ea0a";
}
.pi-award-line:before {
  content: "\ea0b";
}
.pi-shop-line:before {
  content: "\ea0c";
}
.pi-auto-talks-fill:before {
  content: "\ea0d";
}
.pi-auto-talks-line:before {
  content: "\ea0e";
}
.pi-distribuition-fill:before {
  content: "\ea0f";
}
.pi-distribuition-line:before {
  content: "\ea10";
}
.pi-catalogo-fill:before {
  content: "\ea11";
}
.pi-catalogo-line:before {
  content: "\ea12";
}
.pi-category-fill:before {
  content: "\ea13";
}
.pi-category-line:before {
  content: "\ea14";
}
.pi-e-settings-fill:before {
  content: "\ea15";
}
.pi-e-settings-line:before {
  content: "\ea16";
}
.pi-ecommerce-fill:before {
  content: "\ea17";
}
.pi-ecommerce-line:before {
  content: "\ea18";
}
.pi-microphone-fill:before {
  content: "\ea19";
}
.pi-microphone-line:before {
  content: "\ea1a";
}
.pi-order-fill:before {
  content: "\ea1b";
}
.pi-order-line:before {
  content: "\ea1c";
}
.pi-pay-fill:before {
  content: "\ea1d";
}
.pi-pay-line:before {
  content: "\ea1e";
}
.pi-phone-fill:before {
  content: "\ea1f";
}
.pi-phone-line:before {
  content: "\ea20";
}
.pi-products-fill:before {
  content: "\ea21";
}
.pi-products-line:before {
  content: "\ea22";
}
.pi-promo-fill:before {
  content: "\ea23";
}
.pi-promo-line:before {
  content: "\ea24";
}
.pi-text-fast-fill:before {
  content: "\ea25";
}
.pi-text-fast-line:before {
  content: "\ea26";
}
.pi-wallet-fill:before {
  content: "\ea27";
}
.pi-wallet-line:before {
  content: "\ea28";
}
.pi-next-fill:before {
  content: "\ea29";
}
.pi-next-line:before {
  content: "\ea2a";
}
.pi-previous-fill:before {
  content: "\ea2b";
}
.pi-previous-line:before {
  content: "\ea2c";
}
.pi-star-fill:before {
  content: "\ea2d";
}
.pi-star-line:before {
  content: "\ea2e";
}
.pi-normal-fill:before {
  content: "\ea2f";
}
.pi-pig-line:before {
  content: "\ea30";
}
.pi-rocket-line:before {
  content: "\ea31";
}
.pi-speedometer-line:before {
  content: "\ea32";
}
.pi-golden-line:before {
  content: "\ea33";
}
.pi-normal-line:before {
  content: "\ea34";
}
.pi-pig-fill:before {
  content: "\ea35";
}
.pi-rocket-fill:before {
  content: "\ea36";
}
.pi-speedometer-fill:before {
  content: "\ea37";
}
.pi-golden-fill:before {
  content: "\ea38";
}
.pi-brazil-fill:before {
  content: "\ea39";
}
.pi-brazil-line:before {
  content: "\ea3a";
}
