.btn-primary-collapse {
    color: var(--textDefault);
    background-color: transparent;
  }
  
  .btn-primary-collapse:hover {
    color: var(--primary) !important;
  }

  .btn-primary-collapse:focus,
  .btn-primary-collapse.focus {
    color: var(--primary);
    background-color: var(--primary-25);
    border-color: var(--primary-25);
    box-shadow: none;
}
  
  .btn-primary-collapse.disabled,
  .btn-primary-collapse:disabled {
    color: var(--primary);
    background-color: var(--primary);
    border-color: var(--primary);
  }
  
  .btn-primary-collapse:not(:disabled):not(.disabled):active,
  .btn-primary-collapse:not(:disabled):not(.disabled).active,
  .show > .btn-primary-collapse.dropdown-toggle {
    color: var(--primary);
    background-color: var(--primary-25);
    border-color: var(--primary-25);
  }
  
  .btn-primary-collapse:not(:disabled):not(.disabled):active:focus,
  .btn-primary-collapse:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary-collapse.dropdown-toggle:focus {
    box-shadow: none;
}  


.btn-primary-collapse-itens {
    color: var(--textDefault);
    background-color: transparent;
    padding: 0;
    display: flex;
    align-items: center;
     margin-bottom: 1rem;
     margin-left: 12px;
     text-decoration: none !important;

  }
  .btn-primary-collapse-itens>i {
    margin-right: 5px;

}

  .btn-primary-collapse-itens:hover {
    color: var(--primary) !important;
  }

  .btn-primary-collapse-itens:focus,
  .btn-primary-collapse-itens.focus {
    color: var(--primary);
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
}
  
  .btn-primary-collapse-itens.disabled,
  .btn-primary-collapse-itens:disabled {
    color: var(--primary);
    border-color: transparent;
  }
  
  .btn-primary-collapse-itens:not(:disabled):not(.disabled):active,
  .btn-primary-collapse-itens:not(:disabled):not(.disabled).active,
  .show > .btn-primary-collapse-itens.dropdown-toggle {
    color: var(--primary);
    background-color: transparent;
    border-color: transparent;
}
  
  .btn-primary-collapse-itens:not(:disabled):not(.disabled):active:focus,
  .btn-primary-collapse-itens:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary-collapse-itens.dropdown-toggle:focus {
    box-shadow: none;
}  


.pi-simple-arrow-down-line{
    display: block;

    -webkit-animation: fadeInFromNone 0.9s ease-out;
    -moz-animation: fadeInFromNone 0.9s ease-out;
    -o-animation: fadeInFromNone 0.9s ease-out;
    animation: fadeInFromNone 0.9s ease-out;

}

@-webkit-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-moz-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-o-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}


.pi-simple-arrow-right-line-animation{
  display: block;

  -webkit-animation: pi-simple-arrow-right-line-animation 0.9s ease-out;
  -moz-animation: pi-simple-arrow-right-line-animation 0.9s ease-out;
  -o-animation: pi-simple-arrow-right-line-animation 0.9s ease-out;
  animation: pi-simple-arrow-right-line-animation 0.9s ease-out;

}

@-webkit-keyframes pi-simple-arrow-right-line-animation {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
}

@-moz-keyframes pi-simple-arrow-right-line-animation {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
}

@-o-keyframes pi-simple-arrow-right-line-animation {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
}

@keyframes pi-simple-arrow-right-line-animation {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
}